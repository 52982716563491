.results-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-style: solid;
  border-color: #b0b0b0;
  border-width: 1px;
  border-radius: 10px;
  width: 50vw !important;
  padding: 20px;
  margin-top: 24px;
  color: #D9D9D9;
  font-weight: bold;
  font-size: 17px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: space-evenly;
  width: 100%;
}

.button-container button {
  margin: 6px 0;
}
