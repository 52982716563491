.log-in-card{
  width: 25vw;
  padding: 20px;
  border-radius: 5px;
}

.avatar-symbol {
  background-color: #1976d2 !important;
}

.login-input {
  width: 80%;
  margin-top: 20px !important;
}

.submit-btn{
  margin-top: 50px!important;
}

