.modal-box {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: 40%;
  height: 50%;

  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-header {
  color: #D9D9D9;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  height: 15%;
  padding-top: 18px;
  margin-top: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}


.form-content {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  width: 92%;
  height: 80%;
}

.correo-input {
  /* background-color: #2A2B31; */
  border-radius: 4px;
  width: 50%;
  margin-bottom: 30px!important;
}
