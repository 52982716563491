.img-preview-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #909090;
  border-width: 1px;
  border-radius: 10px;
  width: 45%;
  padding: 15px;
  margin-top: 10px;
  color: #D9D9D9;
  font-weight: bold;
  font-size: 17px;
}

.item-drop {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  font-size: 14px;
}

.img-preview {
  margin-top: 24px;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  width: 50vw !important;
  border-color: #b0b0b0;
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
