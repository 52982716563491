.main-dropzone {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dropzone-outer {
  /* background-color: #D9D9D9; */
  width: 50vw !important;
  height: 20vh !important;
}

.dropzone {
  color: #818181 !important
}

.segment-button {
  margin-top: 24px;
}